import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import OkTickIcon from "../../images/icons/check.svg";

import "./GeneralAdjustmentsContent.scss";

const GeneralAdjustmentsContent = () => {
  return (
    <div className="adjust-container">
      <div data-sal="slide-up" data-sal-easing="ease" data-sal-duration="300">
        <h2>Ajustes generales</h2>
      </div>
      <div className="flex-container">
        <div
          className="item-container"
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="300"
        >
          <div className="icon">
            <StaticImage
              alt="Cuatro personas conversando"
              src="../../images/icons/form.pencil.news.svg"
              layout="constrained"
              loading="lazy"
              width={149}
              height={113}
            />
          </div>
          <div>
            <ul>
              <li>
                <LazyLoadImage src={OkTickIcon} alt="" />
                <p>
                  No se borran todos los datos cargados en el login, si la
                  validación arroja que alguno es incorrecto. (App)
                </p>
              </li>
              <li>
                <LazyLoadImage src={OkTickIcon} alt="" />
                <p>
                  Se agrandó el espacio del encabezado utilizado para el logo de
                  la empresa. (App)
                </p>
              </li>
              <li>
                <LazyLoadImage src={OkTickIcon} alt="" />
                <p>
                  Se agregó el color secundario de la empresa en la sección
                  Agenda. (App)
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div
          className="item-container"
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="300"
        >
          <div className="icon">
            <StaticImage
              alt="Cuatro personas conversando"
              src="../../images/icons/process.news.svg"
              layout="constrained"
              loading="lazy"
              width={151}
              height={138}
            />
          </div>
          <div>
            <ul>
              <li>
                <LazyLoadImage src={OkTickIcon} alt="" />
                <p>
                  Se solucionó problema por el cual no se podía realizar cambio
                  de logo. (App)
                </p>
              </li>
              <li>
                <LazyLoadImage src={OkTickIcon} alt="" />
                <p>
                  Corrección en la alineación de la sección de cumpleaños y
                  home. (App)
                </p>
              </li>
              <li>
                <LazyLoadImage src={OkTickIcon} alt="" />
                <p>Ajustes en la redirección de push en noticias. (App)</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralAdjustmentsContent;
