import * as React from "react";
import jsonNews from "../../data/news.json";

import scrollTo from "gatsby-plugin-smoothscroll";

import ActualNewsContent from "../ActualNewsContent/ActualNewsContent";
//import HistoryNewsCard from "../HistoryNewsCard/HistoryNewsCard";

import "./News.scss";
import GeneralAdjustmentsContent from "../GeneralAdjustmentsContent/GeneralAdjustmentsContent";

import ImageHeaderNews from "../../images/illustrations/cards.news.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const News = () => {
	const [data, setData] = React.useState({});
	const [loading, setLoading] = React.useState(false);

	const [countToShow, setCountToShow] = React.useState(3);
	//const [showMoreButton, setShowMoreButton] = React.useState(true);
	//const [showLessButton, setShowLessButton] = React.useState(false);

	React.useEffect(() => {
		let mounted = true;
		if (mounted) {
			setLoading(true);
			setData(jsonNews);
			setCountToShow(3);
			scrollTo("#principal-title");
			setLoading(false);
		}
		return () => {
			mounted = false;
		};
	}, []);

	/*
  const handlePartialView = () => {
    setCountToShow(3);
    setShowLessButton(false);
    setShowMoreButton(true);
    scrollTo("#first-card");
  };

  const handleFullView = () => {
    setCountToShow(10);
    setShowLessButton(true);
    setShowMoreButton(false);
  };

  if (loading) {
    return (
      <div className="roadmap-loader-container">
        <div className="lds-ring">
          <div></div>
        </div>
      </div>
    );
  }
*/
	return (
		<div>
			<div id="principal-title">
				<div className="news-wrapper">
					<div className="news">
						<div
							className="content"
							data-sal="slide-right"
							data-sal-easing="ease"
							data-sal-duration="400">
							<h1 className="title">
								Te mostramos las <b>novedades</b> de <b>Diciembre 2022</b>
							</h1>
						</div>
						<div
							className="images"
							data-sal="slide-left"
							data-sal-easing="ease"
							data-sal-duration="400">
							<LazyLoadImage
								className="title-image"
								src={ImageHeaderNews}
								effect="blur"
							/>
						</div>
					</div>
				</div>

				<div className="news-container">
					<div className="actual-news">
						{data?.news?.actual &&
							data?.news?.actual.map((actual) => {
								return <ActualNewsContent key={actual.id} data={actual} />;
							})}
					</div>
				</div>

        <GeneralAdjustmentsContent />
        {/*
      <div className="history-news-title" id="first-card">
        <h2>MÁS DE QAVANT</h2>
        <h1>Descubrí y explorá nuestras funcionalidades</h1>
      </div>
      <div className="history-news-cards">
        {data?.news?.history &&
          data?.news?.history.slice(0, countToShow).map((history) => {
            return <HistoryNewsCard key={history.id} data={history} />;
          })}
      </div>
      {showLessButton && (
        <div className="open-cards">
          <button type="button" onClick={handlePartialView}>
            Mostrar menos
          </button>
        </div>
      )}
      {showMoreButton && (
        <div className="open-cards">
          <button type="button" onClick={handleFullView}>
            Mostrar más
          </button>
        </div>
      )}
      <div className="next-features">
        <h1>PROXIMAMENTE</h1>
        <div className="section-divisor"></div>
        <div className="subtitle">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </div>
        <div className="features-container">
          <div className="next-feature-card">
            <div>
              <PurpleIcon4 />
            </div>
            <div>
              <h4>Mensajeria interna</h4>
            </div>
            <div>
              <p className="text-muted">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco
              </p>
            </div>
          </div>
          <div className="next-feature-card">
            <div>
              <PurpleIcon4 />
            </div>
            <div>
              <h4>Mensajeria interna</h4>
            </div>
            <div>
              <p className="text-muted">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco
              </p>
            </div>
          </div>
          <div className="next-feature-card">
            <div>
              <PurpleIcon4 />
            </div>
            <div>
              <h4>Mensajeria interna</h4>
            </div>
            <div>
              <p className="text-muted">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco
              </p>
            </div>
          </div>
        </div>
      </div>
      */}
			</div>
		</div>
	);
};

export default News;
